// ShareHandView.tsx
import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import './ShareHandView.css';
import { FaShare } from 'react-icons/fa';

interface ShareHandViewProps {
  viewModel: ViewModel;
}

const ShareHandView = observer(({ viewModel }: ShareHandViewProps) => {
  const handleShareHand = async () => {
    const gameType = viewModel.selectedGameType;
    const numberOfPlayers = viewModel.numberOfPlayers;
    const numberOfSimulations = viewModel.numberOfSimulations;
    const enableTwoBoards = viewModel.enableTwoBoards;
    const handsAndBoards = viewModel.serializeHandsAndBoards();

    const url = `${window.location.origin}/${gameType}/${numberOfPlayers}/${numberOfSimulations}?enableTwoBoards=${enableTwoBoards}&handsAndBoards=${handsAndBoards}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Poker Legend 5',
          text: 'Check out this poker hand:',
          url: url,
        });
      } catch (error) {
        console.error('Error sharing:', error);
        // Fallback to copying URL to clipboard
        copyToClipboard(url);
      }
    } else {
      // Fallback to copying URL to clipboard
      copyToClipboard(url);
    }
  };

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url).then(
      () => {
        alert('URL copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy URL: ', err);
      }
    );
  };

  return (
    <div className="share-hand-view">
      <button className="share-hand-button" onClick={handleShareHand}>
        <FaShare />
      </button>
    </div>
  );
});

export default ShareHandView;