import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import './GameModifiersView.css';
import React from 'react';

interface GameModifiersViewProps {
  viewModel: ViewModel;
}

const GameModifiersView = observer(({ viewModel }: GameModifiersViewProps) => {
  const handleTwoBoardsChange = () => {
    viewModel.setEnableTwoBoards(!viewModel.enableTwoBoards);
  };

  const handleNumberOfSimulationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    viewModel.setNumberOfSimulations(value);
  };

  return (
    <div className="game-modifiers">
      <div className="two-boards-selector">
        <button className="two-boards-button" onClick={handleTwoBoardsChange}>
          {viewModel.enableTwoBoards ? 'Disable Two Boards' : 'Enable Two Boards'}
        </button>
      </div>
      <div className="simulation-controls">
        <div className="simulation-inputs">
          <div className="number-of-simulations-selector">
            <label htmlFor="numberOfSimulations">Simulations:</label>
            <input
              type="range"
              id="numberOfSimulations"
              min={1}
              max={5000}
              step={1}
              value={viewModel.numberOfSimulations}
              onChange={handleNumberOfSimulationsChange}
            />
            <span>{viewModel.numberOfSimulations}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GameModifiersView;