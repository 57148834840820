// Copyright 2023 Anthony Howell. All rights reserved.
// DeckView.tsx
import React, { useState, useEffect } from 'react';
import './DeckView.css';
import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import { Card } from './core/types';
import CardView from './Card';

interface DeckViewProps {
  viewModel: ViewModel;
}

const DeckView = observer(({ viewModel }: DeckViewProps) => {
  const suits = ['H', 'D', 'C', 'S'];
  const [isWrapped, setIsWrapped] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWrapped(window.innerWidth < 725);
    };
    handleResize(); // Initial check on component mount
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCardClick = (card: Card) => {
    if (viewModel.selectedPlayerIndex !== null) {
      const playerIndex = viewModel.selectedPlayerIndex;
      const cardIndex = viewModel.playersCards[playerIndex].findIndex(
        (c) => c.rank === 0 && c.suit === ''
      );
      if (cardIndex !== -1) {
        viewModel.setPlayerCard(playerIndex, cardIndex, card);
      }
    } else if (viewModel.selectedBoard === 'board1') {
      const emptyBoardCardIndex = viewModel.boardCards.findIndex(
        (c) => c.rank === 0 && c.suit === ''
      );
      if (emptyBoardCardIndex !== -1) {
        viewModel.setBoardCard(emptyBoardCardIndex, card);
      }
    } else if (viewModel.selectedBoard === 'board2') {
      const emptyBoardCardIndex = viewModel.boardCards2.findIndex(
        (c) => c.rank === 0 && c.suit === ''
      );
      if (emptyBoardCardIndex !== -1) {
        viewModel.setBoardCard2(emptyBoardCardIndex, card);
      }
    }
  };

  return (
    <div className="deck-container">
      <div className="deck-cards">
        {suits.map((suit) => (
          <div key={suit} className="suit-row">
            {viewModel.deck
              .filter((card) => card.suit === suit)
              .sort((a, b) => a.rank - b.rank)
              .map((card) => (
                <CardView
                  key={card.id}
                  card={card}
                  onClick={() => handleCardClick(card)}
                  inputView={true}
                  wrapped={isWrapped}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
});

export default DeckView;