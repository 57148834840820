// Copyright 2023 Anthony Howell. All rights reserved.
import React from 'react';
import { Card as CardType } from './core/types';
import './Card.css';

interface CardProps {
  card: CardType;
  onClick?: () => void;
  inputView?: boolean;
  wrapped?: boolean;
}

const CardView: React.FC<CardProps> = ({ card, onClick, inputView = false, wrapped = false }) => {
  const suitSymbols: { [key: string]: string } = {
    H: '♥',
    D: '♦',
    C: '♣',
    S: '♠',
  };

  const getRankDisplay = (rank: number) => {
    switch (rank) {
      case 11:
        return 'J';
      case 12:
        return 'Q';
      case 13:
        return 'K';
      case 14:
        return 'A';
      default:
        return rank.toString();
    }
  };

  const getSuitColor = (suit: string) => {
    switch (suit) {
      case 'H':
        return 'red';
      case 'D':
        return 'blue';
      case 'C':
        return 'green';
      case 'S':
        return 'black';
      default:
        return '';
    }
  };

  return (
    <div
      className={`card ${inputView ? 'input-view' : ''} ${wrapped ? 'wrapped' : ''} ${getSuitColor(card.suit)}`}
      onClick={onClick}
    >
      {card.rank ? (
        <div className="card-front">
          <span className="rank">{getRankDisplay(card.rank)}</span>
          <span className={`suit ${getSuitColor(card.suit)}`}>{suitSymbols[card.suit]}</span>
        </div>
      ) : (
        <div className="card-back">
          <div className="white-card-back"></div>
        </div>
      )}
    </div>
  );
};

export default CardView;