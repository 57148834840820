// Copyright 2023 Anthony Howell. All rights reserved.
import { makeAutoObservable } from "mobx";
import { Card, GameType, SimulationResultPercentages, GameTypeExtensions } from './types';
import { SimulationModel } from './SimulationModel';
import { StudSimulationModel } from './StudSimulationModel';

class ViewModel {
  deck: Card[] = [];
  playersCards: Card[][] = [];
  boardCards: Card[] = [];
  boardCards2: Card[] = [];
  simulationResultsBoard1: SimulationResultPercentages[] = [];
  simulationResultsBoard2: SimulationResultPercentages[] = [];
  numberOfPlayers: number = 2;
  selectedGameType: GameType = GameType.Holdem;
  numberOfSimulations: number = 1000;
  selectedPlayerIndex: number | null = null;
  selectedBoard: 'board1' | 'board2' | null = null;
  isSimulating: boolean = false;
  selectedComponent: 'gameType' | 'numberOfPlayers' | 'simControls' | null = null;
  isMenuVisible: boolean = false;
  enableTwoBoards: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.initializeDeck();
    this.initializePlayersCards();
    this.initializeBoardCards();
    this.initializeBoardCards2();
  }

  setNumberOfPlayers = (value: number) => {
    this.numberOfPlayers = value;
    this.initializePlayersCards();
    this.simulationResultsBoard1 = [];
    this.simulationResultsBoard2 = [];
  };

  setBoardCard = (index: number, card: Card) => {
    const currentCard = this.boardCards[index];
    if (currentCard.rank !== 0) {
      this.deck.push(currentCard);
      this.boardCards[index] = {
        rank: 0,
        suit: "",
        id: "",
        description: "",
      };
    } else {
      this.boardCards[index] = card;
      this.removeCardFromDeck(card);
  
      const enteredCards = this.boardCards.filter((card) => card.rank !== 0);
      if (enteredCards.length === 5) {
        this.goBack();
      }
    }
  };
  
  setBoardCard2 = (index: number, card: Card) => {
    const currentCard = this.boardCards2[index];
    if (currentCard.rank !== 0) {
      this.deck.push(currentCard);
      this.boardCards2[index] = {
        rank: 0,
        suit: "",
        id: "",
        description: "",
      };
    } else {
      this.boardCards2[index] = card;
      this.removeCardFromDeck(card);
  
      const enteredCards = this.boardCards2.filter((card) => card.rank !== 0);
      if (enteredCards.length === 5) {
        this.goBack();
      }
    }
  };


  setPlayerCard = (playerIndex: number, cardIndex: number, card: Card) => {
    if (this.selectedPlayerIndex !== null && playerIndex === this.selectedPlayerIndex) {
      const currentCard = this.playersCards[playerIndex][cardIndex];
      if (currentCard.rank !== 0) {
        this.deck.push(currentCard);
        this.playersCards[playerIndex][cardIndex] = {
          rank: 0,
          suit: "",
          id: "",
          description: "",
        };
      } else {
        this.playersCards[playerIndex][cardIndex] = card;
        this.removeCardFromDeck(card);
  
        const maxPlayersCards = GameTypeExtensions[this.selectedGameType].maxPlayersCards;
        const enteredCards = this.playersCards[playerIndex].filter((card) => card.rank !== 0);
        if (enteredCards.length === maxPlayersCards) {
          this.goBack();
        }
      }
    }
  };

  setSelectedComponent = (component: 'gameType' | 'numberOfPlayers' | 'simControls' | null) => {
    this.selectedComponent = component;
  };

  setEnableTwoBoards = (value: boolean) => {
    this.enableTwoBoards = value;
  };

  removeCardFromDeck = (card: Card) => {
    const index = this.deck.findIndex((c) => c.id === card.id);
    if (index !== -1) {
      this.deck.splice(index, 1);
    }
  };

  goBack = () => {
    this.setSelectedPlayerIndex(null);
    this.setSelectedBoard(null);
  };

  initializePlayersCards = () => {
    const maxPlayersCards = GameTypeExtensions[this.selectedGameType].maxPlayersCards;
    this.playersCards = Array.from({ length: this.numberOfPlayers }, () =>
      Array.from({ length: maxPlayersCards }, () => ({
        rank: 0,
        suit: "",
        id: "",
        description: "",
      }))
    );
  };

  clearPlayerCards = (playerIndex: number) => {
    const clearedCards = this.playersCards[playerIndex].filter((card) => card.rank !== 0);
    this.playersCards[playerIndex] = Array.from(
      { length: GameTypeExtensions[this.selectedGameType].maxPlayersCards },
      () => ({
        rank: 0,
        suit: "",
        id: "",
        description: "",
      })
    );
    this.deck.push(...clearedCards);
  };
  
  setSelectedBoard = (selected: 'board1' | 'board2' | null) => {
    this.selectedBoard = selected;
    console.log("selectedBoard is now:", this.selectedBoard);
  };

  toggleMenu = () => {
    this.isMenuVisible = !this.isMenuVisible;
  };

  initializeBoardCards = () => {
    this.boardCards = Array.from({ length: 5 }, () => ({ rank: 0, suit: "", id: "", description: "" }));
  };
  
  initializeBoardCards2 = () => {
    this.boardCards2 = Array.from({ length: 5 }, () => ({ rank: 0, suit: "", id: "", description: "" }));
  };

  setSelectedGameType = (gameType: GameType) => {
    this.selectedGameType = gameType;
    const maxNumberOfPlayers = GameTypeExtensions[gameType].maxNumberOfPlayers;
  
    if (this.numberOfPlayers > maxNumberOfPlayers) {
      this.setNumberOfPlayers(maxNumberOfPlayers);
    } else {
      this.initializePlayersCards();
    }
    this.initializeDeck();
    this.clearAllCards();
    this.simulationResultsBoard1 = [];
    this.simulationResultsBoard2 = [];
  
    // Disable double board for stud games
    if (GameTypeExtensions[gameType].isStudGame) {
      this.setEnableTwoBoards(false);
    }
  };

  setNumberOfSimulations = (value: number) => { 
    this.numberOfSimulations = value;
  };

  setSelectedPlayerIndex = (index: number | null) => {
    if (index === null) {
      this.selectedPlayerIndex = null;
      console.log("selectedPlayerIndex is null");
      this.setSelectedBoard(null);
    } else {
      if (this.selectedPlayerIndex === index) {
        this.selectedPlayerIndex = null;
        console.log("selectedPlayerIndex is null");
      } else {
        this.selectedPlayerIndex = index;
        console.log("selectedPlayerIndex is not null");
      }
      this.setSelectedBoard(null);
    }
  };

  clearAllCards = () => {
    this.playersCards = Array.from({ length: this.numberOfPlayers }, () =>
      Array.from({ length: GameTypeExtensions[this.selectedGameType].maxPlayersCards }, () => ({
        rank: 0,
        suit: "",
        id: "",
        description: "",
      }))
    );
    this.boardCards = Array.from({ length: 5 }, () => ({ rank: 0, suit: "", id: "", description: "" }));
    this.boardCards2 = Array.from({ length: 5 }, () => ({ rank: 0, suit: "", id: "", description: "" }));
    this.initializeDeck();
    this.simulationResultsBoard1 = [];
    this.simulationResultsBoard2 = [];
  };
  
  serializeHandsAndBoards = (): string => {
    const hands = this.playersCards.map(hand => hand.map(card => card.id).join(',')).join('|');
    const board1 = this.boardCards.map(card => card.id).join(',');
    let serializedData = `${hands}~${board1}`;
  
    if (this.enableTwoBoards) {
      const board2 = this.boardCards2.map(card => card.id).join(',');
      serializedData += `~${board2}`;
    }
  
    console.log("serializedData:", serializedData);
    return serializedData;
  };
  
  deserializeHandsAndBoards = (serializedData: string): void => {
    console.log('Deserializing hands and boards with data:', serializedData);
    const [handsData, ...boardsData] = serializedData.split('~');
    const handsList = handsData.split('|').map(hand => hand.split(','));

    this.clearAllCards();

    handsList.forEach((hand, playerIndex) => {
        this.setSelectedPlayerIndex(playerIndex);

        hand.forEach((cardId, cardIndex) => {
            if (cardId !== '') {
                const card = this.deck.find(c => c.id === cardId);
                if (card) {
                    this.setPlayerCard(playerIndex, cardIndex, card);
                    this.removeCardFromDeck(card);
                }
            }
        });
    });

    console.log('Deserialized playersCards:', this.playersCards);

    const board1List = boardsData[0].split(',');
    board1List.forEach((cardId, index) => {
        if (cardId !== '') {
            const card = this.deck.find(c => c.id === cardId);
            if (card) {
                this.setBoardCard(index, card);
                this.removeCardFromDeck(card);
            }
        }
    });

    if (this.enableTwoBoards && boardsData[1]) {
        const board2List = boardsData[1].split(',');
        board2List.forEach((cardId, index) => {
            if (cardId !== '') {
                const card = this.deck.find(c => c.id === cardId);
                if (card) {
                    this.setBoardCard2(index, card);
                    this.removeCardFromDeck(card);
                }
            }
        });
    }

    this.setSelectedPlayerIndex(null);
  };

  runSims = async () => {
    this.isSimulating = true;

    setTimeout(() => {
      if (GameTypeExtensions[this.selectedGameType].isStudGame)  {
        const studSimulationModel = new StudSimulationModel(
          this.deck,
          this.playersCards,
          this.selectedGameType,
          this.numberOfSimulations
        );

        studSimulationModel.runSimulations()
          .then((results) => {
            this.simulationResultsBoard1 = results;
            console.log("Results:", results);
            this.isSimulating = false;
          })
          .catch((error) => {
            console.error("Error running Stud simulations:", error);
            this.isSimulating = false;
          });
      } else {
        const simulationModel1 = new SimulationModel(
          this.deck,
          this.playersCards,
          this.boardCards,
          this.numberOfPlayers,
          this.selectedGameType,
          this.numberOfSimulations
        );

        simulationModel1.runSimulations()
          .then((results1) => {
            this.simulationResultsBoard1 = results1;
            console.log("Results:", results1);

            if (this.enableTwoBoards) {
              const simulationModel2 = new SimulationModel(
                this.deck,
                this.playersCards,
                this.boardCards2,
                this.numberOfPlayers,
                this.selectedGameType,
                this.numberOfSimulations
              );

              simulationModel2.runSimulations()
                .then((results2) => {
                  this.simulationResultsBoard2 = results2;
                  // Combine results logic if needed
                  this.isSimulating = false;
                })
                .catch((error) => {
                  console.error("Error running simulations on the second board:", error);
                  this.isSimulating = false;
                });
            } else {
              this.isSimulating = false;
            }
          })
          .catch((error) => {
            console.error("Error running simulations on the first board:", error);
            this.isSimulating = false;
          });
      }
    }, 50);
  };

  private initializeDeck() {
    this.deck = [];
    const suits = ["H", "D", "C", "S"];
    for (let rank = 2; rank <= 14; rank++) {
      for (const suit of suits) {
        const card: Card = {
          rank,
          suit,
          id: `${rank}${suit}`,
          description: `${rank}${suit}`,
        };
        this.deck.push(card);
      }
    }
  }
}

export default ViewModel;
