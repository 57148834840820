// Copyright 2023 Anthony Howell. All rights reserved.

import { CorePokerUtility } from './CorePokerUtilities';
import { Card, GameType, GameTypeExtensions } from './types';

class StudGameLogic {
    deck: Card[];
    playersCards: Card[][];
    selectedGameType: GameType;
  
    constructor(deck: Card[], playersCards: Card[][], selectedGameType: GameType) {
        this.deck = deck;
        this.playersCards = playersCards;
        this.selectedGameType = selectedGameType;
    }
  
    determineWinnersAndTies(): [number[][], number[][], number[], number[]] {
        const highPlayerStrengths: number[] = [];
        const lowPlayerStrengths: number[] = [];
        let highResults: number[][] = [];
        let lowResults: number[][] = [];
    
        for (const playerHand of this.playersCards) {
            const playerCombinations = this.returnAllCombinations(playerHand);
            highPlayerStrengths.push(this.highestRelativeHandStrength(playerCombinations));
        
            if (GameTypeExtensions[this.selectedGameType].isLowGame) {
                lowPlayerStrengths.push(this.lowestRelativeHandStrength(playerCombinations, this.selectedGameType));
            }
        }
    
        const highestHighStrength = Math.max(...highPlayerStrengths);
        const countHighWinners = highPlayerStrengths.filter((strength) => strength === highestHighStrength).length;
    
        highResults = highPlayerStrengths.map((strength) =>
            strength === highestHighStrength ? (countHighWinners > 1 ? [0, 1] : [1, 0]) : [0, 0]
        );
    
        if (GameTypeExtensions[this.selectedGameType].isLowGame) {
            const highestLowStrength = Math.min(...lowPlayerStrengths.filter((strength) => strength !== 10000000));
            const countLowWinners = lowPlayerStrengths.filter((strength) => strength === highestLowStrength).length;
        
            lowResults = lowPlayerStrengths.map((strength) =>
                strength !== 10000000 && strength === highestLowStrength ? (countLowWinners > 1 ? [0, 1] : [1, 0]) : [0, 0]
            );
        }
    
        return [highResults, lowResults, highPlayerStrengths, lowPlayerStrengths];
    }
  
      highestRelativeHandStrength(combinations: Card[][]): number {
        return Math.max(...combinations.map((combination) => CorePokerUtility.highHandStrength(combination)));
      }
    
      lowestRelativeHandStrength(combinations: Card[][], selectedGameType?: GameType): number {
        if (selectedGameType === GameType.Razz) {
          return Math.min(...combinations.map((combination) => CorePokerUtility.lowHandStrengthRazz(combination, selectedGameType)));
        } else {
          return Math.min(...combinations.map((combination) => CorePokerUtility.lowHandStrength(combination)));
        }
      }
  
    returnAllCombinations(hand: Card[]): Card[][] {
        const combinations: Card[][] = [];
    
        if (hand.length !== 7) {
            return combinations;
        }
    
        for (let i = 0; i < hand.length; i++) {
            for (let j = i + 1; j < hand.length; j++) {
                for (let k = j + 1; k < hand.length; k++) {
                    for (let l = k + 1; l < hand.length; l++) {
                        for (let m = l + 1; m < hand.length; m++) {
                            const combination = [hand[i], hand[j], hand[k], hand[l], hand[m]];
                            combinations.push(combination);
                        }
                    }
                }
            }
        }
    
        return combinations;
    }
}
  
export { StudGameLogic };
