// Copyright 2023 Anthony Howell. All rights reserved.

import { Card, GameType, SimulationResultPercentages, GameTypeExtensions } from './types';
import { GameLogic } from './GameLogic';
import { CorePokerUtility } from './CorePokerUtilities';

class SimulationModel {
  simulationResults: SimulationResultPercentages[] = [];
  isSimulating = false;
  numberOfSimulations: number;
  deck: Card[];
  playersCards: Card[][];
  boardCards: Card[];
  numberOfPlayers: number;
  selectedGameType: GameType;
  maxBoardCards = 5;

  constructor(
    deck: Card[],
    playersCards: Card[][],
    boardCards: Card[],
    numberOfOpponents: number,
    selectedGameType: GameType,
    numberOfSimulations: number
  ) {
    this.deck = deck;
    this.playersCards = playersCards;
    this.boardCards = boardCards;
    this.numberOfPlayers = numberOfOpponents;
    this.selectedGameType = selectedGameType;
    this.numberOfSimulations = numberOfSimulations;
  }

  async runSimulations(): Promise<SimulationResultPercentages[]> {
    const anyPlayersCardsAreBlank = this.playersCards.some((cards) => cards.some((card) => card.rank === 0));

    const highSimulationResults: number[][][] = [];
    const lowSimulationResults: number[][][] = [];
    const highHandStrengths: number[][] = [];
    const lowHandStrengths: number[][] = [];

    const originalBoard = [...this.boardCards];
    const originalDeck = [...this.deck];
    const boardCardCount = this.boardCards.filter((card) => card.rank !== 0).length;

    const appendSimulationResults = (
      highResults: number[][],
      lowResults: number[][],
      highStrengths: number[],
      lowStrengths: number[]
    ) => {
      highSimulationResults.push(highResults);
      lowSimulationResults.push(lowResults);
      highHandStrengths.push(highStrengths);
      lowHandStrengths.push(lowStrengths);
    };

    if (anyPlayersCardsAreBlank) {
      for (let i = 0; i < this.numberOfSimulations; i++) {
        if (boardCardCount === 5) {
          this.boardCards = originalBoard;
        } else {
          this.boardCards = [...originalBoard.slice(0, boardCardCount), ...this.getRandomBoard(this.maxBoardCards - boardCardCount)];
        }
        const { highResults, lowResults, highStrengths, lowStrengths } = await this.simulateOneRound();
        appendSimulationResults(highResults, lowResults, highStrengths, lowStrengths);
        this.resetBoardAndDeck(originalBoard, originalDeck);
      }
    } else {
      const remainingDeck = this.getRemainingDeck();

      if (boardCardCount === 5) {
        const { highResults, lowResults, highStrengths, lowStrengths } = await this.simulateOneRound();
        appendSimulationResults(highResults, lowResults, highStrengths, lowStrengths);
      } else if (boardCardCount === 3) {
        for (let i = 0; i < remainingDeck.length - 1; i++) {
          for (let j = i + 1; j < remainingDeck.length; j++) {
            this.boardCards = [...originalBoard.slice(0, 3), remainingDeck[i], remainingDeck[j]];
            const { highResults, lowResults, highStrengths, lowStrengths } = await this.simulateOneRound();
            appendSimulationResults(highResults, lowResults, highStrengths, lowStrengths);
            this.resetBoardAndDeck(originalBoard, originalDeck);
          }
        }
      } else if (boardCardCount === 4) {
        for (const card of remainingDeck) {
          this.boardCards = [...originalBoard.slice(0, 4), card];
          const { highResults, lowResults, highStrengths, lowStrengths } = await this.simulateOneRound();
          appendSimulationResults(highResults, lowResults, highStrengths, lowStrengths);
          this.resetBoardAndDeck(originalBoard, originalDeck);
        }
      } else {
        for (let i = 0; i < this.numberOfSimulations; i++) {
          this.boardCards = this.getRandomBoard(this.maxBoardCards);
          const { highResults, lowResults, highStrengths, lowStrengths } = await this.simulateOneRound();
          appendSimulationResults(highResults, lowResults, highStrengths, lowStrengths);
          this.resetBoardAndDeck(originalBoard, originalDeck);
        }
      }
    }

    const totalSimulations = highSimulationResults.length;
    this.simulationResults = CorePokerUtility.calculateAndStoreResults(
      highSimulationResults,
      lowSimulationResults,
      highHandStrengths,
      lowHandStrengths,
      totalSimulations,
      this.playersCards.length,
      this.selectedGameType
    );

    return this.simulationResults;
  }

  async simulateOneRound(): Promise<{
    highResults: number[][];
    lowResults: number[][];
    highStrengths: number[];
    lowStrengths: number[];
  }> {
    const localPlayersCards = this.playersCards.map((playerHand) =>
      playerHand.map((card) => (card.rank === 0 ? this.getRandomCard() : card))
    );

    const gameLogic = new GameLogic(this.deck, localPlayersCards, this.boardCards, this.selectedGameType);
    const [highResults, lowResults, highStrengths, lowStrengths] = gameLogic.determineWinnersAndTies();

    return {
      highResults,
      lowResults,
      highStrengths,
      lowStrengths,
    };
  }

  private getRandomCard(): Card {
    const remainingDeck = this.getRemainingDeck();
    const randomIndex = Math.floor(Math.random() * remainingDeck.length);
    return remainingDeck[randomIndex];
  }

  getRandomBoard(missingCards: number): Card[] {
    const remainingDeckCopy = [...this.getRemainingDeck()];
    const board: Card[] = [];

    while (board.length < missingCards) {
      const randomIndex = Math.floor(Math.random() * remainingDeckCopy.length);
      const randomCard = remainingDeckCopy[randomIndex];
      if (randomCard) {
        board.push(randomCard);
        remainingDeckCopy.splice(randomIndex, 1);
      }
    }

    return board;
  }

  private resetBoardAndDeck(originalBoard: Card[], originalDeck: Card[]) {
    this.boardCards = originalBoard.slice();
    this.deck = originalDeck.slice();
  }

  private getRemainingDeck(): Card[] {
    return this.deck.filter(
      (card) =>
        !this.playersCards.some((hand) => hand.some((playerCard) => playerCard.id === card.id)) &&
        !this.boardCards.some((boardCard) => boardCard.id === card.id)
    );
  }
}

export { SimulationModel }