// Copyright 2023 Anthony Howell. All rights reserved.

import { CorePokerUtility } from './CorePokerUtilities';
import { Card, GameType, GameTypeExtensions } from './types';

class GameLogic {
  deck: Card[];
  playersCards: Card[][];
  boardCards: Card[];
  selectedGameType: GameType;

  constructor(deck: Card[], playersCards: Card[][], boardCards: Card[], selectedGameType: GameType) {
    this.deck = deck;
    this.playersCards = playersCards;
    this.boardCards = boardCards;
    this.selectedGameType = selectedGameType;
  }

  determineWinnersAndTies(): [number[][], number[][], number[], number[]] {
    if (this.boardCards.length === 0) {
      return [[], [], [], []];
    }

    const highPlayerStrengths: number[] = [];
    const lowPlayerStrengths: number[] = [];
    let highResults: number[][] = [];
    let lowResults: number[][] = [];

    for (const playerHand of this.playersCards) {
      const playerCombinations = this.returnAllCombinations(playerHand, this.boardCards);
      highPlayerStrengths.push(this.highestRelativeHandStrength(playerCombinations));

      if (GameTypeExtensions[this.selectedGameType].isLowGame) {
        lowPlayerStrengths.push(this.lowestRelativeHandStrength(playerCombinations, this.selectedGameType));
      }
    }

    const highestHighStrength = Math.max(...highPlayerStrengths);
    const countHighWinners = highPlayerStrengths.filter((strength) => strength === highestHighStrength).length;

    highResults = highPlayerStrengths.map((strength) =>
      strength === highestHighStrength ? (countHighWinners > 1 ? [0, 1] : [1, 0]) : [0, 0]
    );

    if (GameTypeExtensions[this.selectedGameType].isLowGame) {
      const highestLowStrength = Math.min(...lowPlayerStrengths.filter((strength) => strength !== 10000000));
      const countLowWinners = lowPlayerStrengths.filter((strength) => strength === highestLowStrength).length;

      lowResults = lowPlayerStrengths.map((strength) =>
        strength !== 10000000 && strength === highestLowStrength ? (countLowWinners > 1 ? [0, 1] : [1, 0]) : [0, 0]
      );
    }

    return [highResults, lowResults, highPlayerStrengths, lowPlayerStrengths];
  }

  highestRelativeHandStrength(combinations: Card[][]): number {
    return Math.max(...combinations.map((combination) => CorePokerUtility.highHandStrength(combination)));
  }

  lowestRelativeHandStrength(combinations: Card[][], selectedGameType?: GameType): number {
    return Math.min(...combinations.map((combination) => CorePokerUtility.lowHandStrength(combination)));
  }

  returnAllCombinations(hand: Card[], board: Card[]): Card[][] {
    const selectedGameType = this.selectedGameType;
    const combinations: Card[][] = [];

    if (hand.length !== GameTypeExtensions[selectedGameType].maxPlayersCards || board.length !== 5) {
      return combinations;
    }

    const allCards = [...hand, ...board];

    switch (selectedGameType) {
      case GameType.Holdem:
        if (hand.length === 2) {
          for (let i = 0; i < allCards.length; i++) {
            for (let j = i + 1; j < allCards.length; j++) {
              for (let k = j + 1; k < allCards.length; k++) {
                for (let l = k + 1; l < allCards.length; l++) {
                  for (let m = l + 1; m < allCards.length; m++) {
                    const combination = [allCards[i], allCards[j], allCards[k], allCards[l], allCards[m]];
                    combinations.push(combination);
                  }
                }
              }
            }
          }
        }
        break;
      case GameType.Plo:
      case GameType.Plo8:
      case GameType.FivePLO:
      case GameType.FivePLO8:
      case GameType.SixPLO:
      case GameType.SixPLO8:
        for (let i = 0; i < hand.length; i++) {
          for (let j = i + 1; j < hand.length; j++) {
            const heroHandPart = [hand[i], hand[j]];

            for (let k = 0; k < board.length; k++) {
              for (let l = k + 1; l < board.length; l++) {
                for (let m = l + 1; m < board.length; m++) {
                  const boardCombination = [board[k], board[l], board[m]];
                  const combination = [...heroHandPart, ...boardCombination];
                  combinations.push(combination);
                }
              }
            }
          }
        }
        break;
      default:
        break;
    }

    return combinations;
  }
}

export { GameLogic };
