// Copyright 2023 Anthony Howell. All rights reserved.
// SimulationResultsModal.tsx

import { observer } from 'mobx-react';
import { SimulationResultPercentages, GameType, GameTypeExtensions } from './core/types';
import './SimulationResultsModal.css';
import ViewModel from './core/ViewModel';
import { useEffect, useRef, useState } from 'react';
import { getCombinedSimulationResults } from './core/CombineResults';

interface SimulationResultsModalProps {
  selectedGameType: GameType;
  playerIndex: number;
  viewModel: ViewModel;
  onClose: () => void;
}

const SimulationResultsModal = observer(({ selectedGameType, playerIndex, viewModel, onClose }: SimulationResultsModalProps) => {
  const isLowGameType = GameTypeExtensions[selectedGameType].isLowGame;
  const [selectedBoard, setSelectedBoard] = useState<'board1' | 'board2' | 'combined'>('combined');

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const getSelectedResult = (): SimulationResultPercentages | undefined => {
    if (selectedBoard === 'board1') {
      return viewModel.simulationResultsBoard1[playerIndex];
    } else if (selectedBoard === 'board2') {
      return viewModel.simulationResultsBoard2[playerIndex];
    } else {
      const combinedResults = getCombinedSimulationResults(
        viewModel.simulationResultsBoard1,
        viewModel.simulationResultsBoard2,
        viewModel.enableTwoBoards
      );
      return combinedResults[playerIndex];
    }
  };

  const result = getSelectedResult();

  if (!result) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Simulation Results</h2>
          {viewModel.enableTwoBoards && (
            <div className="board-selection">
              <button
                className={selectedBoard === 'board1' ? 'active' : ''}
                onClick={() => setSelectedBoard('board1')}
              >
                Board 1
              </button>
              <button
                className={selectedBoard === 'board2' ? 'active' : ''}
                onClick={() => setSelectedBoard('board2')}
              >
                Board 2
              </button>
              <button
                className={selectedBoard === 'combined' ? 'active' : ''}
                onClick={() => setSelectedBoard('combined')}
              >
                Combined
              </button>
            </div>
          )}
        </div>
        <div className="modal-body">
          {isLowGameType && selectedGameType !== GameType.Razz && (
            <div className="equity-item">
              <p>Overall Equity: {result.overallEquity.toFixed(2)}%</p>
              <div className="percentage-line" style={{ width: `${result.overallEquity}%` }}></div>
            </div>
          )}
         {isLowGameType ? (
  selectedGameType === GameType.Razz ? (
    <div className="equity-group">
      <div className="equity-item">
        <p>Low Equity: {result.lowEquity.toFixed(2)}%</p>
        <div className="percentage-line" style={{ width: `${result.lowEquity}%` }}></div>
      </div>
      <div className="equity-item">
        <p>Low Wins: {result.lowWins.toFixed(2)}%</p>
        <div className="percentage-line" style={{ width: `${result.lowWins}%` }}></div>
      </div>
      <div className="equity-item">
        <p>Low Ties: {result.lowTies.toFixed(2)}%</p>
        <div className="percentage-line" style={{ width: `${result.lowTies}%` }}></div>
      </div>
    </div>
  ) : (
    <>
      <div className="equity-group">
        <div className="equity-item">
          <p>High Equity: {result.highEquity.toFixed(2)}%</p>
          <div className="percentage-line" style={{ width: `${result.highEquity}%` }}></div>
        </div>
        <div className="equity-item">
          <p>High Wins: {result.highWins.toFixed(2)}%</p>
          <div className="percentage-line" style={{ width: `${result.highWins}%` }}></div>
        </div>
        <div className="equity-item">
          <p>High Ties: {result.highTies.toFixed(2)}%</p>
          <div className="percentage-line" style={{ width: `${result.highTies}%` }}></div>
        </div>
      </div>
      <div className="equity-group">
        <div className="equity-item">
          <p>Low Equity: {result.lowEquity.toFixed(2)}%</p>
          <div className="percentage-line" style={{ width: `${result.lowEquity}%` }}></div>
        </div>
        <div className="equity-item">
          <p>Low Wins: {result.lowWins.toFixed(2)}%</p>
          <div className="percentage-line" style={{ width: `${result.lowWins}%` }}></div>
        </div>
        <div className="equity-item">
          <p>Low Ties: {result.lowTies.toFixed(2)}%</p>
          <div className="percentage-line" style={{ width: `${result.lowTies}%` }}></div>
        </div>
      </div>
    </>
  )
 ) : (
            <div className="equity-group">
              <div className="equity-item">
                <p>Equity: {result.highEquity.toFixed(2)}%</p>
                <div className="percentage-line" style={{ width: `${result.highEquity}%` }}></div>
              </div>
              <div className="equity-item">
                <p>Wins: {result.highWins.toFixed(2)}%</p>
                <div className="percentage-line" style={{ width: `${result.highWins}%` }}></div>
              </div>
              <div className="equity-item">
                <p>Ties: {result.highTies.toFixed(2)}%</p>
                <div className="percentage-line" style={{ width: `${result.highTies}%` }}></div>
              </div>
            </div>
          )}
          {selectedGameType !== GameType.Razz && (
            <div className="hand-strength-percentages">
              <h3>Hand Strength Percentages:</h3>
              {result.highHandCardDistributionPercentages.slice().reverse().map((percentage, index) => (
                <div key={index} className="equity-item">
                  <p>{percentage.category}: {percentage.percentage.toFixed(2)}%</p>
                  <div className="percentage-line" style={{ width: `${percentage.percentage}%` }}></div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
});

export default SimulationResultsModal;
