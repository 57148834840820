// Copyright 2023 Anthony Howell. All rights reserved.

import { Card, GameType, SimulationResultPercentages, GameTypeExtensions } from './types';
import { StudGameLogic } from './StudGameLogic';
import { CorePokerUtility } from './CorePokerUtilities';

class StudSimulationModel {
  simulationResults: SimulationResultPercentages[] = [];
  isSimulating = false;
  numberOfSimulations: number;
  deck: Card[];
  playersCards: Card[][];
  selectedGameType: GameType;

  constructor(
    deck: Card[],
    playersCards: Card[][],
    selectedGameType: GameType,
    numberOfSimulations: number
  ) {
    this.deck = deck;
    this.playersCards = playersCards;
    this.selectedGameType = selectedGameType;
    this.numberOfSimulations = numberOfSimulations;
  }

  async runSimulations(): Promise<SimulationResultPercentages[]> {
    const highSimulationResults: number[][][] = [];
    const lowSimulationResults: number[][][] = [];
    const highHandStrengths: number[][] = [];
    const lowHandStrengths: number[][] = [];
    
    const originalDeck = [...this.deck];
    const appendSimulationResults = (
      highResults: number[][],
      lowResults: number[][],
      highStrengths: number[],
      lowStrengths: number[]
    ) => {
      highSimulationResults.push(highResults);
      lowSimulationResults.push(lowResults);
      highHandStrengths.push(highStrengths);
      lowHandStrengths.push(lowStrengths);
    };

    const allPlayersHaveMaxCards = this.playersCards.every(
      (playerHand) => playerHand.every((card) => card.rank !== 0)
    );

    console.log('allPlayersHaveMaxCards', allPlayersHaveMaxCards);

    const simulationCount = allPlayersHaveMaxCards ? 1 : this.numberOfSimulations;

    for (let i = 0; i < simulationCount; i++) {
      const localPlayersCards = this.playersCards.map((playerHand) =>
        playerHand.every((card) => card.rank !== 0)
          ? playerHand
          : playerHand.map((card) => (card.rank === 0 ? this.getRandomCard() : card))
      );

      const { highResults, lowResults, highStrengths, lowStrengths } = await this.simulateOneRound(localPlayersCards);
      appendSimulationResults(highResults, lowResults, highStrengths, lowStrengths);
      this.resetDeck(originalDeck);
    }

    this.simulationResults = CorePokerUtility.calculateAndStoreResults(
      highSimulationResults,
      lowSimulationResults,
      highHandStrengths,
      lowHandStrengths,
      simulationCount,
      this.playersCards.length,
      this.selectedGameType
    );

    return this.simulationResults;
  }

  async simulateOneRound(localPlayersCards: Card[][]): Promise<{
    highResults: number[][];
    lowResults: number[][];
    highStrengths: number[];
    lowStrengths: number[];
  }> {
    const studGameLogic = new StudGameLogic(this.deck, localPlayersCards, this.selectedGameType);
    const [highResults, lowResults, highStrengths, lowStrengths] = studGameLogic.determineWinnersAndTies();

    return {
      highResults,
      lowResults,
      highStrengths,
      lowStrengths,
    };
  }

  generateRandomCards(count: number): Card[] {
    const remainingDeck = this.getRemainingDeck();
    const randomCards: Card[] = [];

    while (randomCards.length < count) {
      const randomIndex = Math.floor(Math.random() * remainingDeck.length);
      const randomCard = remainingDeck[randomIndex];
      randomCards.push(randomCard);
      remainingDeck.splice(randomIndex, 1);
    }

    return randomCards;
  }

  getRandomCard(): Card {
    const remainingDeck = this.getRemainingDeck();
    const randomIndex = Math.floor(Math.random() * remainingDeck.length);
    return remainingDeck[randomIndex];
  }

  generateRandomHand(): Card[] {
    return CorePokerUtility.generateRandomHand(this.getRemainingDeck(), this.selectedGameType);
  }

  private resetDeck(originalDeck: Card[]) {
    this.deck = originalDeck.slice();
  }

  private getRemainingDeck(): Card[] {
    return this.deck.filter(
      (card) =>
        !this.playersCards.some((hand) => hand.some((playerCard) => playerCard.id === card.id))
    );
  }
}

export { StudSimulationModel };