// Copyright 2023 Anthony Howell. All rights reserved.
import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import './MenuView.css';
import { useState } from 'react';
import GameModifiersView from './GameModifiersView';

interface MenuViewProps {
  viewModel: ViewModel;
}

const MenuView = observer(({ viewModel }: MenuViewProps) => {
  const [showInstructions, setShowInstructions] = useState(false);

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  return (
    <div className="menu-container" style={{ height: '100vh', overflow: 'auto' }}>
      <div className="menu">
        <div className="menu-content">
          <div className="info-section">
          {!showInstructions ? (
  <>
    <h2>Welcome to Poker Legend 5</h2>
    <p>
      The best poker odds and insights calculator on the internet. Current games supported are Texas Hold'em,
      Pot Limit Omaha (PLO), 5-Card PLO, 6-Card PLO, Stud, Razz, and high-low variants of poker. We also support Double
      Board / Two Boards.
    </p>
    <div>
      <button className="contact-button" onClick={() => window.location.href = 'mailto:anthony@pulcer.net'}>
        Contact me
      </button>
      <button
        className="twitter-button"
        onClick={() => window.open('https://twitter.com/AnthonyHowell88', '_blank')}
      >
        Twitter
      </button>
      <button className="website-button" onClick={() => window.open('https://pulcer.net/', '_blank')}>
        Website
      </button>
      <button
        className="video-button"
        onClick={() => window.open('https://www.youtube.com/watch?v=ga-J6mKva4c', '_blank')}
      >
        How To Video
      </button>
      <button className="instructions-button" onClick={toggleInstructions}>
        Show Instructions
      </button>
    </div>
  </>
            ) : (
              <>
                <h3>Instructions:</h3>
                <ol>
                  <li>Select the number of players, number of simulations, and game type.</li>
                  <li>Click "Run Sims" to get equity percentages.</li>
                  <li>
                    Click on the equity percentages to view advanced analytics, including win/tie percentages, high/low
                    equities (if available), and hand distribution breakdown.
                  </li>
                </ol>
                <p>
                  To run your hand against a range, leave all the players' cards blank to create a random hand range.
                  For a more specific range, enter partial card information for an opponent.
                </p>
                <p>
                  If you want to save your settings, click share hand and bookmark that URL. All your settings will be
                  saved.
                </p>
                <p>
                  For example to always navigate to a game with 4 players that runs 2,500 simulations and is for 5 card
                  omaha you would click here/save this bookmark: https://pokerlegend5.com/5PLO/4/2500
                </p>
                <button className="instructions-button" onClick={toggleInstructions}>
                  Hide Instructions
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {!showInstructions && <GameModifiersView viewModel={viewModel} />}
      {/* Go Back Button */}
      <div className="settings-button-container">
        <button className="go-back-button" onClick={viewModel.toggleMenu}>
          Go Back
        </button>
      </div>
    </div>
  );
});

export default MenuView;