import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import ActionButtonsView from './ActionButtonsView';
import PlayersCardsView from './PlayersCardsView';
import BoardCardsView from './BoardCardsView';
import DeckView from './DeckView';
import MenuView from './MenuView';
import { useParams, useLocation } from 'react-router-dom';
import './MainView.css';
import { useEffect } from 'react';
import { GameType, GameTypeExtensions } from './core/types';
import ShareHandView from './ShareHandView';

interface MainViewProps {
  viewModel: ViewModel;
}

const MainView = observer(({ viewModel }: MainViewProps) => {
  const isPlayerSelected = viewModel.selectedPlayerIndex !== null;
  const isBoardSelected = viewModel.selectedBoard;
  const showBackButton = isPlayerSelected || isBoardSelected;
  const { gameType, numberOfPlayers, numberOfSimulations } = useParams<{
    gameType?: string;
    numberOfPlayers?: string;
    numberOfSimulations?: string;
  }>();
  const location = useLocation();
  const isStudType = GameTypeExtensions[viewModel.selectedGameType].isStudGame;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const enableTwoBoards = searchParams.get('enableTwoBoards') === 'true';
    const handsAndBoards = searchParams.get('handsAndBoards');
  
    if (gameType && Object.values(GameType).includes(gameType as GameType)) {
      viewModel.setSelectedGameType(gameType as GameType);
    }
    if (numberOfPlayers) {
      const parsedNumberOfPlayers = parseInt(numberOfPlayers, 10);
      if (!isNaN(parsedNumberOfPlayers)) {
        viewModel.setNumberOfPlayers(parsedNumberOfPlayers);
      }
    }
    if (numberOfSimulations) {
      const parsedNumberOfSimulations = parseInt(numberOfSimulations, 10);
      if (!isNaN(parsedNumberOfSimulations)) {
        viewModel.setNumberOfSimulations(parsedNumberOfSimulations);
      }
    }
    viewModel.setEnableTwoBoards(enableTwoBoards);
  
    // Call deserializeHandsAndBoards after setting the other parameters
    if (handsAndBoards) {
      viewModel.deserializeHandsAndBoards(handsAndBoards);
    }
  }, [gameType, numberOfPlayers, numberOfSimulations, location.search, viewModel]);

  return (
    <div className="main-content">
      {viewModel.isMenuVisible ? (
        <MenuView viewModel={viewModel} />
      ) : (
        <div className="left-section">
          {!isPlayerSelected && !isBoardSelected && (
            <>
              <PlayersCardsView viewModel={viewModel} />
              {!isStudType && <BoardCardsView viewModel={viewModel} />}
            </>
          )}
          {isPlayerSelected && (
            <>
              <PlayersCardsView viewModel={viewModel} />
              <DeckView viewModel={viewModel} />
            </>
          )}
          {isBoardSelected && !isStudType && (
            <>
              <BoardCardsView viewModel={viewModel} />
              <DeckView viewModel={viewModel} />
            </>
          )}
          {showBackButton && (
            <div className="back-button-container">
              <button className="back-button" onClick={viewModel.goBack}>
                Go Back
              </button>
            </div>
          )}
          {!isPlayerSelected && !isBoardSelected && (
            <div className="action-buttons-container">
              <ShareHandView viewModel={viewModel} />
              <ActionButtonsView viewModel={viewModel} />
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default MainView;
