import { SimulationResultPercentages } from './types';

export function getCombinedSimulationResults(
  simulationResultsBoard1: SimulationResultPercentages[],
  simulationResultsBoard2: SimulationResultPercentages[] | undefined,
  enableTwoBoards: boolean
): SimulationResultPercentages[] {
  if (!enableTwoBoards) {
    return simulationResultsBoard1;
  }

  if (!simulationResultsBoard1 || !simulationResultsBoard2) {
    return [];
  }

  const maxLength = Math.max(simulationResultsBoard1.length, simulationResultsBoard2.length);

  return Array.from({ length: maxLength }, (_, index) => {
    const result1 = simulationResultsBoard1[index] || {
      highWins: 0,
      highTies: 0,
      lowWins: 0,
      lowTies: 0,
      highHandStrengths: [],
      lowHandStrengths: [],
      highEquity: 0,
      lowEquity: 0,
      overallEquity: 0,
      highHandCardDistributionPercentages: [],
    };
    const result2 = simulationResultsBoard2[index] || {
      highWins: 0,
      highTies: 0,
      lowWins: 0,
      lowTies: 0,
      highHandStrengths: [],
      lowHandStrengths: [],
      highEquity: 0,
      lowEquity: 0,
      overallEquity: 0,
      highHandCardDistributionPercentages: [],
    };

    const combinedHandDistributionPercentages = result1.highHandCardDistributionPercentages.map(
      (category1, categoryIndex) => {
        const category2 = result2.highHandCardDistributionPercentages[categoryIndex];
        const percentage =
          ((category1?.percentage || 0) + (category2?.percentage || 0)) / 2;
        return { category: category1.category, percentage };
      }
    );
    
    return {
      highWins: (result1.highWins + result2.highWins) / 2,
      highTies: (result1.highTies + result2.highTies) / 2,
      lowWins: (result1.lowWins + result2.lowWins) / 2,
      lowTies: (result1.lowTies + result2.lowTies) / 2,
      highHandStrengths: [...result1.highHandStrengths, ...result2.highHandStrengths],
      lowHandStrengths: [...result1.lowHandStrengths, ...result2.lowHandStrengths],
      highEquity: (result1.highEquity + result2.highEquity) / 2,
      lowEquity: (result1.lowEquity + result2.lowEquity) / 2,
      overallEquity: (result1.overallEquity + result2.overallEquity) / 2,
      highHandCardDistributionPercentages: combinedHandDistributionPercentages,
    };
  });
}