// CorePokerUtility.ts

import { Card, GameType, GameTypeExtensions, SimulationResultPercentages } from './types';

class CorePokerUtility {

    static highHandStrength(hand: Card[]): number {
        if (hand.length !== 5) {
          return 0;
        }
    
        const sortedHand = hand.sort((a, b) => a.rank - b.rank);
    
        const isFlush = new Set(hand.map((card) => card.suit)).size === 1;
        const isStraightHand = this.isStraight(sortedHand);
    
        if (isStraightHand && isFlush) {
          if (sortedHand[0].rank === 10 && sortedHand[4].rank === 14) {
            return 10000000;
          }
          return JSON.stringify(sortedHand.map((card) => card.rank)) === JSON.stringify([2, 3, 4, 5, 14]) ? 9000000 + 5 : 9000000 + sortedHand[4].rank;
        }
    
        const rankCounts = hand.reduce((counts: { [rank: string]: number }, card) => {
          counts[card.rank] = (counts[card.rank] || 0) + 1;
          return counts;
        }, {});
        const sortedRankCounts = Object.values(rankCounts).sort((a, b) => b - a);
    
        switch (true) {
          case sortedRankCounts[0] === 4:
            const fourOfAKindRank = Object.keys(rankCounts).find((rank) => rankCounts[rank] === 4) || '0';
            const kickerRank = Object.keys(rankCounts).find((rank) => rankCounts[rank] === 1) || '0';
            return 8000000 + Number(fourOfAKindRank) * 100 + Number(kickerRank);
          case sortedRankCounts[0] === 3 && sortedRankCounts[1] === 2:
            const threeOfAKindRank = Object.keys(rankCounts).find((rank) => rankCounts[rank] === 3) || '0';
            const pairRank = Object.keys(rankCounts).find((rank) => rankCounts[rank] === 2) || '0';
            return 7000000 + Number(threeOfAKindRank) * 10 + Number(pairRank);
          case isFlush:
            return (
              6000000 +
              sortedHand
                .reverse()
                .reduce((score, card, index) => score + card.rank * Math.pow(10, 4 - index), 0)
            );
          case isStraightHand:
            return JSON.stringify(sortedHand.map((card) => card.rank)) === JSON.stringify([2, 3, 4, 5, 14]) ? 5000000 + 5 : 5000000 + sortedHand[4].rank;
          case sortedRankCounts[0] === 3 && sortedRankCounts[1] === 1:
            const threeOfAKindRankOnly = Object.keys(rankCounts).find((rank) => rankCounts[rank] === 3) || '0';
            const kickers = sortedHand.filter((card) => card.rank !== Number(threeOfAKindRankOnly)).map((card) => card.rank).sort((a, b) => b - a);
            const kickerScore = kickers[0] * 100 + kickers[1];
            return 4000000 + Number(threeOfAKindRankOnly) * 100 + kickerScore;
          case sortedRankCounts[0] === 2 && sortedRankCounts[1] === 2:
            const pairs = Object.keys(rankCounts)
              .filter((rank) => rankCounts[rank] === 2)
              .map(Number)
              .sort((a, b) => b - a);
            const kicker = sortedHand.find((card) => card.rank !== pairs[0] && card.rank !== pairs[1])?.rank || 0;
            return 3000000 + pairs[0] * 1000 + pairs[1] * 70 + kicker;
          case sortedRankCounts.includes(2):
            const pairRankInTwoPair = Math.max(...Object.keys(rankCounts).filter((rank) => rankCounts[rank] === 2).map(Number));
            const kickersInTwoPair = sortedHand
              .filter((card) => card.rank !== pairRankInTwoPair)
              .map((card) => card.rank)
              .sort((a, b) => b - a);
            const kickerScoreInTwoPair = kickersInTwoPair.reduce((score, rank, index) => score + rank * [700, 50, 1][index], 0);
            return 2000000 + pairRankInTwoPair * 1000 + kickerScoreInTwoPair;
          default:
            return (
              1000000 +
              sortedHand.reduce((score, card, index) => score + card.rank * [40000, 2800, 200, 14, 1][index], 0)
            );
        }
      }
    

      static lowHandStrength(hand: Card[]): number {
        const adjustedHand = hand.map((card) => (card.rank === 14 ? { ...card, rank: 1 } : card));
        const validLowCards = adjustedHand.filter((card) => card.rank <= 8).sort((a, b) => a.rank - b.rank);
        const uniqueRanks = new Set(validLowCards.map((card) => card.rank));
    
        if (uniqueRanks.size !== 5) {
          return 10000000;
        }
    
        return validLowCards.reverse().reduce((score, card, index) => score + card.rank * Math.pow(10, index), 0);
      }

      static lowHandStrengthRazz(hand: Card[], gameType: GameType = GameType.Razz): number {
        const sortedHand = hand.sort((a, b) => a.rank - b.rank);
        const adjustedHand = sortedHand.map((card) => (card.rank === 14 ? { ...card, rank: 1 } : card));
        const maxRank = gameType === GameType.Razz ? 14 : 8;
        const validLowCards = adjustedHand.filter((card) => card.rank <= maxRank).sort((a, b) => a.rank - b.rank);
        const uniqueRanks = new Set(validLowCards.map((card) => card.rank));
      
        if (uniqueRanks.size === 4) {
          // Hand contains exactly one pair
          const pairRank = validLowCards.find((card, index) => validLowCards[index + 1]?.rank === card.rank)!.rank;
          return validLowCards.reduce((score, card, index) => {
            if (card.rank === pairRank) {
              return score + card.rank * Math.pow(10, 6);
            } else {
              return score + card.rank * Math.pow(10, index);
            }
          }, 0);
        } else if (uniqueRanks.size !== 5) {
          // Hand contains more than one pair or other invalid combinations
          return Number.MAX_SAFE_INTEGER;
        }
      
        // Hand contains no pairs
        return validLowCards.reduce((score, card, index) => score + card.rank * Math.pow(10, index), 0);
      }

   static isStraight(hand: Card[]): boolean {
    const ranks = hand.map((card) => card.rank);
    return ranks.every((rank, index) => index === 0 || ranks[index - 1] === rank - 1) || JSON.stringify(ranks) === JSON.stringify([2, 3, 4, 5, 14]);
  }

  static calculateAndStoreResults(
    highSimulationResults: number[][][],
    lowSimulationResults: number[][][],
    highHandStrengths: number[][],
    lowHandStrengths: number[][],
    totalSimulations: number,
    playersCardsLength: number,
    selectedGameType: GameType
  ): SimulationResultPercentages[] {
    const results: SimulationResultPercentages[] = [];

    for (let playerIndex = 0; playerIndex < playersCardsLength; playerIndex++) {
      const highResult = CorePokerUtility.sumSimulationResults(highSimulationResults, playerIndex);
      const highWinPercentage = (highResult[0] / totalSimulations) * 100;
      const highTiePercentage = (highResult[1] / totalSimulations) * 100;
      const playerHighHandStrengths = highHandStrengths.map((strengths) => strengths[playerIndex]);

      let lowWinPercentage = 0;
      let lowTiePercentage = 0;
      let playerLowHandStrengths: number[] = [];

      if (GameTypeExtensions[selectedGameType].isLowGame) {
        const lowResult = CorePokerUtility.sumSimulationResults(lowSimulationResults, playerIndex);
        lowWinPercentage = (lowResult[0] / totalSimulations) * 100;
        lowTiePercentage = (lowResult[1] / totalSimulations) * 100;
        playerLowHandStrengths = lowHandStrengths.map((strengths) => strengths[playerIndex]);
      }

      const highEquity = highWinPercentage + highTiePercentage / 2;
      const lowEquity = lowWinPercentage + lowTiePercentage / 2;

      const simulationResult: SimulationResultPercentages = {
        highWins: highWinPercentage,
        highTies: highTiePercentage,
        lowWins: lowWinPercentage,
        lowTies: lowTiePercentage,
        highHandStrengths: playerHighHandStrengths,
        lowHandStrengths: playerLowHandStrengths,
        highEquity,
        lowEquity,
        overallEquity: 0, // We'll calculate this later
        highHandCardDistributionPercentages: CorePokerUtility.calculateHandDistributionPercentages(playerHighHandStrengths, totalSimulations),
      };

      results.push(simulationResult);
    }

    // Calculate overallEquity for each player
    const totalHighEquity = results.reduce((sum, result) => sum + result.highEquity, 0);
    const totalLowEquity = GameTypeExtensions[selectedGameType].isLowGame
      ? results.reduce((sum, result) => sum + result.lowEquity, 0)
      : 0;
    const totalEquity = totalHighEquity + totalLowEquity;

    results.forEach((result) => {
      const playerHighEquity = result.highEquity;
      const playerLowEquity = GameTypeExtensions[selectedGameType].isLowGame ? result.lowEquity : 0;
      result.overallEquity = ((playerHighEquity + playerLowEquity) / totalEquity) * 100;
    });

    return results;
  }

  static calculateHandDistributionPercentages(
    handStrengths: number[],
    totalSimulations: number
  ): { category: string; percentage: number }[] {
    const handCategories: [number, number, string][] = [
      [9000000, 9999999, "Straight Flush"],
      [8000000, 8999999, "Four of a Kind"],
      [7000000, 7999999, "Full House"],
      [6000000, 6999999, "Flush"],
      [5000000, 5999999, "Straight"],
      [4000000, 4999999, "Three of a Kind"],
      [3000000, 3999999, "Two Pair"],
      [2000000, 2999999, "Pair"],
      [1000000, 1999999, "High Card"],
    ];
  
    const counts: { [category: string]: number } = {};
  
    for (const strength of handStrengths) {
      for (const [min, max, category] of handCategories) {
        if (strength >= min && strength <= max) {
          counts[category] = (counts[category] || 0) + 1;
          break;
        }
      }
    }
  
    const percentages = handCategories.map(([_, __, category]) => {
      const count = counts[category] || 0;
      const percentage = (count / handStrengths.length) * 100;
      return { category, percentage };
    });
  
    return percentages;
  }
  
  static generateRandomHand(
    remainingDeck: Card[],
    selectedGameType: GameType
  ): Card[] {
    const randomHand: Card[] = [];
    const handSize = GameTypeExtensions[selectedGameType].maxPlayersCards;
  
    while (randomHand.length < handSize) {
      const randomCard = remainingDeck[Math.floor(Math.random() * remainingDeck.length)];
      if (randomCard) {
        randomHand.push(randomCard);
        remainingDeck.splice(
          remainingDeck.findIndex((card) => card.id === randomCard.id),
          1
        );
      }
    }
  
    return randomHand;
  }
  
  static sumSimulationResults(simulationResults: number[][][], playerIndex: number): number[] {
    const sumResults = [0, 0];
  
    for (const simulation of simulationResults) {
      const result = simulation[playerIndex] || [0, 0];
      sumResults[0] += result[0];
      sumResults[1] += result[1];
    }
  
    return sumResults;
  }


}

export { CorePokerUtility };