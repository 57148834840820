// Copyright 2023 Anthony Howell. All rights reserved.
// SimulationResultsView.tsx
import { observer } from 'mobx-react';
import { useState } from 'react';
import { GameType, GameTypeExtensions } from './core/types';
import './SimulationResultsView.css';
import SimulationResultsModal from './SimulationResultsModal';
import ViewModel from './core/ViewModel';
import { getCombinedSimulationResults } from './core/CombineResults';

interface SimulationResultsViewProps {
  selectedGameType: GameType;
  playerIndex: number;
  viewModel: ViewModel;
}

const SimulationResultsView = observer(({ selectedGameType, playerIndex, viewModel }: SimulationResultsViewProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLowGameType = GameTypeExtensions[selectedGameType].isLowGame;
  const combinedSimulationResults = getCombinedSimulationResults(
    viewModel.simulationResultsBoard1,
    viewModel.simulationResultsBoard2,
    viewModel.enableTwoBoards
  );

  const percentage =
    isLowGameType && selectedGameType === GameType.Razz
      ? combinedSimulationResults[playerIndex]?.lowEquity || 0
      : isLowGameType
      ? combinedSimulationResults[playerIndex]?.overallEquity || 0
      : combinedSimulationResults[playerIndex]?.highEquity || 0;

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="simulation-results" onClick={handleClick}>
        <div className="percentage-container">
          <div className="percentage-background" style={{ height: `${percentage}%` }}></div>
          <p>{percentage.toFixed(2)}%</p>
        </div>
      </div>
      {isModalOpen && (
        <SimulationResultsModal
          selectedGameType={selectedGameType}
          playerIndex={playerIndex}
          viewModel={viewModel}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
});

export default SimulationResultsView;