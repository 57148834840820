import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import { GameType, GameTypeExtensions } from './core/types';
import './ActionButtonsView.css';
import React, { useState, useRef, useEffect } from 'react';

interface ActionButtonsViewProps {
  viewModel: ViewModel;
}

const ActionButtonsView = observer(({ viewModel }: ActionButtonsViewProps) => {
  const [isGameTypeModalOpen, setIsGameTypeModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClearAllClick = () => {
    viewModel.clearAllCards();
  };

  const handleRunSimsClick = () => {
    viewModel.runSims();
    viewModel.setSelectedComponent(null);
  };

  const handleMenuClick = () => {
    viewModel.toggleMenu();
  };

  const handleGameTypeClick = () => {
    setIsGameTypeModalOpen(true);
  };

  const handleGameTypeChange = (gameType: GameType) => {
    viewModel.setSelectedGameType(gameType);
    setIsGameTypeModalOpen(false);
  };
  
  const handleNumberOfPlayersChange = (change: number) => {
    const newNumberOfPlayers = viewModel.numberOfPlayers + change;
    if (
      newNumberOfPlayers >= 2 &&
      newNumberOfPlayers <= GameTypeExtensions[viewModel.selectedGameType].maxNumberOfPlayers
    ) {
      viewModel.setNumberOfPlayers(newNumberOfPlayers);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsGameTypeModalOpen(false);
      }
    };

    if (isGameTypeModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isGameTypeModalOpen]);

  return (
    <div className="action-buttons-container">
      {viewModel.isSimulating ? (
        <div className="calculating-animation">
          <div className="spinner"></div>
          <span>Calculating...</span>
        </div>
      ) : (
        <>
          <div className="top-buttons">
            <button className="menu-button" onClick={handleMenuClick} disabled={viewModel.isSimulating}>
              Menu
            </button>
            <button className="quantity-button increase" onClick={() => handleNumberOfPlayersChange(1)}>
              +
            </button>
            <button className="game-type-button" onClick={handleGameTypeClick} disabled={viewModel.isSimulating}>
              {viewModel.selectedGameType}
            </button>
          </div>
          <div className="bottom-buttons">
            <button className="clear-all-button" onClick={handleClearAllClick} disabled={viewModel.isSimulating}>
              Clear All
            </button>
            <button className="quantity-button decrease" onClick={() => handleNumberOfPlayersChange(-1)}>
              -
            </button>
            <button className="run-sims-button" onClick={handleRunSimsClick} disabled={viewModel.isSimulating}>
              Run Sims
            </button>
          </div>
        </>
      )}
      {isGameTypeModalOpen && (
        <div className="game-type-modal">
          <div className="game-type-modal-content" ref={modalRef}>
            {Object.values(GameType).map((gameType) => (
              <button
                key={gameType}
                onClick={() => handleGameTypeChange(gameType)}
                className={`game-type-modal-button ${viewModel.selectedGameType === gameType ? 'selected' : ''}`}
              >
                {gameType}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default ActionButtonsView;