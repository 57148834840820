// Copyright 2023 Anthony Howell. All rights reserved.
// PlayersCardsView.tsx
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import ViewModel from './core/ViewModel';
import CardView from './Card';
import SimulationResultsView from './SimulationResultsView';
import './PlayersCardsView.css';
import { Card } from './core/types';
import { getCombinedSimulationResults } from './core/CombineResults';

interface PlayersCardsViewProps {
  viewModel: ViewModel;
}

const PlayersCardsView = observer(({ viewModel }: PlayersCardsViewProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 520);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 520);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isPlayerSelected = viewModel.selectedPlayerIndex !== null;
  const isBoardSelected = viewModel.selectedBoard;

  const handleCardClick = (playerIndex: number, cardIndex: number, card: Card) => {
    if (isPlayerSelected && viewModel.selectedPlayerIndex === playerIndex) {
      viewModel.setPlayerCard(playerIndex, cardIndex, card);
    }
  };

  const combinedSimulationResults = getCombinedSimulationResults(
    viewModel.simulationResultsBoard1,
    viewModel.simulationResultsBoard2,
    viewModel.enableTwoBoards
  );
  
  return (
    <div className="players-cards-view">
      {isPlayerSelected && viewModel.selectedPlayerIndex !== null ? (
        <div className={`player-container ${isMobile ? 'mobile' : ''}`}>
          <div className="player-hand selected">
            <div className="card-container">
              {viewModel.playersCards[viewModel.selectedPlayerIndex].map((card: Card, index: number) => (
                <CardView
                  key={index}
                  card={card}
                  onClick={() => handleCardClick(viewModel.selectedPlayerIndex!, index, card)}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        viewModel.playersCards.map((player, playerIndex) => (
          <div key={playerIndex} className={`player-container ${isMobile ? 'mobile' : ''}`}>
            <div className="player-hand" onClick={() => viewModel.setSelectedPlayerIndex(playerIndex)}>
              <div className="card-container">
                {player.map((card, index) => (
                  <CardView key={index} card={card} onClick={() => handleCardClick(playerIndex, index, card)} />
                ))}
              </div>
            </div>
            {combinedSimulationResults[playerIndex] && !isBoardSelected && (
              <SimulationResultsView
                selectedGameType={viewModel.selectedGameType}
                playerIndex={playerIndex}
                viewModel={viewModel}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
});

export default PlayersCardsView;