// Copyright 2023 Anthony Howell. All rights reserved.
// BoardCardsView.tsx
import { observer } from 'mobx-react';
import ViewModel from './core/ViewModel';
import CardView from './Card';
import './BoardCardsView.css';
import { Card } from './core/types';

interface BoardCardsViewProps {
  viewModel: ViewModel;
}

const BoardCardsView = observer(({ viewModel }: BoardCardsViewProps) => {
  const handleBoardClick = (board: 'board1' | 'board2') => {
    if (viewModel.selectedBoard !== board) {
      viewModel.setSelectedBoard(board);
    }
  };

  const handleCardClick = (index: number, card: Card) => {
    if (viewModel.selectedBoard === 'board1' && card.rank !== 0) {
      viewModel.setBoardCard(index, { rank: 0, suit: "", id: "", description: "" });
    }
  };

  const handleCardClick2 = (index: number, card: Card) => {
    if (viewModel.selectedBoard === 'board2' && card.rank !== 0) {
      viewModel.setBoardCard2(index, { rank: 0, suit: "", id: "", description: "" });
    }
  };

  return (
    <div className="board-cards-view">
      {(!viewModel.selectedBoard || viewModel.selectedBoard === 'board1') && (
        <div className="board-cards-wrapper">
          <div
            className={`board-cards ${viewModel.selectedBoard === 'board1' ? 'selected' : ''}`}
            onClick={() => handleBoardClick('board1')}
          >
            <div className="board-cards-inner">
              {viewModel.boardCards.map((card, index) => (
                <CardView key={index} card={card} onClick={() => handleCardClick(index, card)} />
              ))}
            </div>
          </div>
        </div>
      )}
      {viewModel.enableTwoBoards && (!viewModel.selectedBoard || viewModel.selectedBoard === 'board2') && (
        <div className="board-cards-wrapper">
          <div
            className={`board-cards ${viewModel.selectedBoard === 'board2' ? 'selected' : ''}`}
            onClick={() => handleBoardClick('board2')}
          >
            <div className="board-cards-inner">
              {viewModel.boardCards2.map((card, index) => (
                <CardView key={index} card={card} onClick={() => handleCardClick2(index, card)} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default BoardCardsView;