// types.ts
export interface Card {
  rank: number;
  suit: string;
  id: string;
  description: string;
}

export enum GameType {
  Holdem = "Holdem",
  Plo = "PLO",
  Plo8 = "PLO8",
  FivePLO = "5PLO",
  FivePLO8 = "5PLO8",
  SixPLO = "6PLO",
  SixPLO8 = "6PLO8",
  Stud = "Stud",
  Stud8 = "Stud8",
  Razz = "Razz",
}

export interface GameTypeExtensionsData {
  maxPlayersCards: number;
  maxNumberOfPlayers: number;
  isLowGame: boolean;
  isStudGame: boolean;
  isPureLowGame: boolean;
}

export const GameTypeExtensions: Record<GameType, GameTypeExtensionsData> = {
  [GameType.Holdem]: {
    maxPlayersCards: 2,
    maxNumberOfPlayers: 9,
    isLowGame: false,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.Plo]: {
    maxPlayersCards: 4,
    maxNumberOfPlayers: 9,
    isLowGame: false,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.Plo8]: {
    maxPlayersCards: 4,
    maxNumberOfPlayers: 9,
    isLowGame: true,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.FivePLO]: {
    maxPlayersCards: 5,
    maxNumberOfPlayers: 8,
    isLowGame: false,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.FivePLO8]: {
    maxPlayersCards: 5,
    maxNumberOfPlayers: 8,
    isLowGame: true,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.SixPLO]: {
    maxPlayersCards: 6,
    maxNumberOfPlayers: 6,
    isLowGame: false,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.SixPLO8]: {
    maxPlayersCards: 6,
    maxNumberOfPlayers: 6,
    isLowGame: true,
    isStudGame: false,
    isPureLowGame: false,
  },
  [GameType.Stud]: {
    maxPlayersCards: 7,
    maxNumberOfPlayers: 8,
    isLowGame: false,
    isStudGame: true,
    isPureLowGame: false,
  },
  [GameType.Stud8]: {
    maxPlayersCards: 7,
    maxNumberOfPlayers: 8,
    isLowGame: true,
    isStudGame: true,
    isPureLowGame: false,
  },
  [GameType.Razz]: {
    maxPlayersCards: 7,
    maxNumberOfPlayers: 8,
    isLowGame: true,
    isStudGame: true,
    isPureLowGame: true,
  },
};

export interface SimulationResultPercentages {
  highWins: number;
  highTies: number;
  lowWins: number;
  lowTies: number;
  highHandStrengths: number[];
  lowHandStrengths: number[];
  highEquity: number;
  lowEquity: number;
  overallEquity: number;
  highHandCardDistributionPercentages: { category: string; percentage: number }[];
}

export {};